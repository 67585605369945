import { VaporProvider } from '@vapor/ui';
import Head from 'next/head';
import { SWRConfig } from 'swr';
import '../styles/global.css';
import '../styles/reset.css';
import '../styles/variables.css';

function MyApp({ Component, pageProps }) {
  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false
      }}
    >
      <VaporProvider ssr>
        <Head>
          <title>Weekrise</title>

          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#417ff7" />
          <meta name="msapplication-TileColor" content="#417ff7" />
          <meta name="theme-color" content="#efefef" />
        </Head>

        <Component {...pageProps} />
      </VaporProvider>
    </SWRConfig>
  );
}

export default MyApp;
